import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useRecoilState, useSetRecoilState } from 'recoil'
import installPromptState from '../state/installPromptState'

import { log } from '../utils/logger'
import { isIosSafari } from '../utils/iosPwa'
import { installedAppLastUsed, usingInstalledApp } from '../utils/pwa'
import {
    useImmerRecoilSetState,
    useImmerRecoilState,
} from '../state/immerRecoil'
import { iosInstallState } from './IosInstall'
import GlobeSvg from './svg/GlobeSvg'
import { sidenavExpandedAtom } from './sidenav/SideNav'
import { sidenavState } from './sidenav/state'

const installKeyFrames = keyframes`
  from {
    margin-right: -50px;
    transform: none;
  }

  50% {
    margin-right: 0px;
    transform: none;
  }
  40% {
    margin-right: 0px;
    transform: none;
  }
  70% {
    transform:rotate3d(0, 1, 0, 180deg);
    background-color: white !important;
  }
  100% {
    margin-right: 0px;
    transform: rotate3d(0);
  }

`
const Container = styled.div`
    display: contents;
    color: black;
    margin: -50px;
    fill: black;
    //background-color: rgba(255, 255, 255, 0.1);
    align-items: center;
    padding: 5px 50px 5px 10px;
    height: 32px;
    button {
    }
`
export default function GlobeIcon() {
    const setExpaned = useSetRecoilState(sidenavExpandedAtom)
    const setSidenavState = useImmerRecoilSetState(sidenavState)
    async function click() {
        setExpaned(true)
        setSidenavState((x) => {
            x.currentPage = 2
        })
    }

    return (
        <Container>
            <button
                id="install-button"
                className=" fixed left-2 bottom-5 update text-black border-0 bg-yellow-500 rounded ml-2 flex flex-col items-center p-1"
                onClick={click}
            >
                <GlobeSvg></GlobeSvg>
            </button>
        </Container>
    )
}
