import PouchDB from 'pouchdb-browser'
import config from '../config'
import React, { useEffect } from 'react'
import { useImmerRecoilSetState } from '../state/immerRecoil'
import { externalAtom } from './external.state'
import getLogger from '../utils/logger'
import { visibilityEventEmitter } from '../utils/browser.utils'
import { globalLocationsAtom } from '../location'
import { Coordinates } from '@claptastic/common'

const logger = getLogger('external sync')
//new PouchDB('serverDb').destroy().catch((err) => null)

export const localSyncDb = new PouchDB('serverDb', {
    auto_compaction: true,
})

const remoteSyncDb = new PouchDB(config.apiServer + '/claptastic-public')
let lastSequence: string | number = ''
// const test = remoteSyncDb
//     .changes({ live: true, include_docs: true })
//     .on('change', (x) => {
//         console.log('lol x', x)
//         lastSequence = x.seq
//     })
//export const remoteDb = new PouchDB(config.apiServer + '/claptastic-public')

function onError(err) {
    logger.error('sync error', err)
}

function setupSync() {
    return localSyncDb.replicate
        .from(config.apiServer + '/claptastic-public', {
            live: true,
            retry: true,
            checkpoint: 'target',
        })
        .on('error', onError)
}

let sync = setupSync()

visibilityEventEmitter.addEventListener((event) => {
    try {
        if (event === 'hidden') {
            if (!sync) return
            sync.cancel()
            sync.removeAllListeners()
            sync = null
            logger.log('Cancelling sync')
        } else {
            logger.log('Setting up sync')
            sync = setupSync()
        }
    } catch (err) {}
})

export default function ExternalDbSync() {
    const setState = useImmerRecoilSetState(externalAtom)
    const setGlobalLocations = useImmerRecoilSetState(globalLocationsAtom)
    useEffect(() => {
        localSyncDb
            .changes<any>({ live: true, include_docs: true })
            .on('change', (change) => {
                logger.debug('change', change)
                if (change.id === 'summary') {
                    setState((x) => {
                        x.count = change.doc.count
                    })
                } else if (change.id === 'locations') {
                    const doc = change.doc
                    const keys = Object.keys(doc)
                    setGlobalLocations((locations) => {
                        keys.forEach((key) => {
                            if (key.includes('|')) {
                                const parts = key.split('|').map((x) => +x)
                                locations[key] = [parts[0], parts[1]]
                            }
                        })
                    })
                }
            })
            .on('error', (error) => {
                logger.error('error syncing', error)
            })
    }, [])
    return <></>
}
