import React, {
    useState,
    useEffect,
    HTMLAttributes,
    ReactNode,
    ReactPortal,
    useRef,
} from 'react'
import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components'

const Container = styled.div``

export interface OverlayProps extends HTMLAttributes<HTMLDivElement> {}

export function Overlay({ ...props }: OverlayProps) {
    return (
        <>
            {props.children}
            <div id="overlay-id" className="fixed inset-0 contents"></div>
        </>
    )
}

export interface PutSomeStuffInProps {
    children: ReactNode
}

export function PutSomeStuffIn({ children }: PutSomeStuffInProps) {
    const rootRef = useRef<HTMLElement>()
    const eleRef = useRef<HTMLElement>()
    useEffect(() => {
        rootRef.current = document.getElementById('overlay-id')
        eleRef.current = document.createElement('div')
        rootRef.current.appendChild(eleRef.current)
        return () => {
            rootRef.current.removeChild(eleRef.current)
        }
    }, [])

    if (!rootRef.current) {
        return null
    }

    return createPortal(children, eleRef.current)
}
