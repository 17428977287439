import React, {
    useEffect,
    HTMLAttributes,
    createRef,
    useRef,
    useState,
    MouseEventHandler,
} from 'react'

import * as L from 'leaflet'
import { Button } from '../toolkit/Button'

import { useLocationServices } from '../../location/LocationServices'
import { useRecoilValue } from 'recoil'
import { globalLocationsAtom } from '../../location'
import { geoLocation } from '@claptastic/common'

export interface MapProps extends HTMLAttributes<HTMLDivElement> {}

export function GlobalClaps({ ...props }: MapProps) {
    const geoLocations = useRecoilValue(globalLocationsAtom)
    const ref = createRef<HTMLDivElement>()
    const [map, setMap] = useState<L.Map>()
    const addedCoordsRef = useRef<any>({})

    const {
        state: { location, permission, loading, consented },
        getLocation,
        consentToGeoLocation,
    } = useLocationServices()

    const showPermissionDenied = permission === 'denied'
    const showRequestConsent = !consented
    const showMap = !showPermissionDenied && !showRequestConsent

    useEffect(() => {
        if (!ref.current) {
            return
        }
        const map = L.map('mapid')
        if (location) {
            map.setView(location, 16)
        } else {
            map.setView([-41.2765, 174.775], 16)
        }
        L.tileLayer(
            'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
            {
                attribution:
                    'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                minZoom: 2,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                accessToken:
                    'pk.eyJ1IjoiZGFueW8xMzk5IiwiYSI6ImNrbjRqYzQ0eTB2a3MybnFzcWJtdWVmZ2UifQ.4o-OqMqfRNFw9P_WX9o9-w',
            },
        ).addTo(map)
        setMap(map)
        return () => {
            if (map && map.remove) {
                map.off()
                map.remove()
            }
        }
    }, [showMap, ref.current])

    useEffect(() => {
        if (map) {
            Object.keys(geoLocations).forEach((key) => {
                if (!addedCoordsRef.current.hasOwnProperty(key)) {
                    const coords = geoLocations[key]
                    const geo = geoLocation(coords)
                    console.log('adding geolocation', coords)
                    L.marker(coords).addTo(map)
                    L.rectangle(geo.getTile(), {
                        color: '#ff7800',
                        weight: 1,
                    }).addTo(map)
                    addedCoordsRef.current[key] = undefined
                }
            })

            addedCoordsRef.current
        }
    }, [geoLocations, map])

    if (showPermissionDenied) {
        return <PermissionDenied></PermissionDenied>
    }
    if (showRequestConsent) {
        return (
            <RequestConsent
                click={() => {
                    consentToGeoLocation()
                    getLocation()
                }}
            />
        )
    }

    return (
        <div className="w-full h-full bg-gray-800   text-white">
            <div id="mapid" ref={ref} className="h-full"></div>
        </div>
    )
}

function PermissionDenied() {
    return (
        <div className="mt-5">
            <p className=" mb-2">
                You have explicitly denied access to location services in your
                browser.
            </p>
            <p>Please allow location services to clap around the world</p>
        </div>
    )
}

function RequestConsent({
    click,
}: {
    click: MouseEventHandler<HTMLButtonElement>
}) {
    return (
        <div className="mt-5">
            <p className="mb-8 text-center">
                Allow location services to clap around the world
            </p>

            <div className="block mx-auto px-10 ">
                <Button onClick={click}>Allow Access</Button>
            </div>
            <p className="mb-10 text-center text-sm mt-2">
                Once allowed, for privacy reasons we only map a general location
                when you clap
            </p>
        </div>
    )
}
