import { atom } from 'recoil'
import { useImmerRecoilState } from '../state/immerRecoil'
import { useVisibilityChange } from '../hooks/useVisibilityChange'
import { useEffect } from 'react'
import { getCurrentLocation } from '../utils/device'
import getLogger from '../utils/logger'

const logger = getLogger('location services')
export interface LocationState {
    permission?: PermissionState
    consented: boolean
    location?: [number, number]
    locationAddDate?: number
    loading: boolean
    error?: any
}

const GEO_LOCATION_STORAGE_KEY = 'GEO_LOCATION'
const locationConsent = {
    geoApiConsented() {
        return !!localStorage[GEO_LOCATION_STORAGE_KEY]
    },
    setGeoApiConsented(value: boolean) {
        const valueAsString = value ? '1' : ''
        localStorage[GEO_LOCATION_STORAGE_KEY] = valueAsString
    },
}

export type PermissionState = 'granted' | 'denied' | 'prompt' | 'unsupported'

export const locationAtom = atom<LocationState>({
    key: 'locationAtom',
    default: { loading: false, consented: locationConsent.geoApiConsented() },
})

export function useLocationServices() {
    const [state, setState] = useImmerRecoilState(locationAtom)
    function getLocation() {
        logger.log('Getting location')
        setState((x) => {
            x.error = undefined
            x.loading = true
        })
        getCurrentLocation()
            .then((loc) => {
                if (loc) {
                    logger.log('Geolocation obtained', loc)
                    locationConsent.setGeoApiConsented(true)
                    setState((x) => {
                        x.location = [loc.coords.latitude, loc.coords.longitude]
                        x.consented = true
                        x.locationAddDate = Date.now()
                    })
                }
            })
            .catch((err) => {
                logger.warn('failed to get geolocation', err)
                setState((x) => {
                    x.error = err
                })
            })
            .finally(() => {
                setState((x) => {
                    x.loading = false
                })
            })
    }

    function consentToGeoLocation() {
        locationConsent.setGeoApiConsented(true)
        setState((x) => {
            x.consented = true
        })
    }
    function refreshPermission() {
        if (navigator?.permissions) {
            logger.log('Refreshing permissions')
            navigator.permissions
                .query({
                    name: 'geolocation',
                })
                .then((state) => {
                    logger.log('geo permission response', state)
                    setState((x) => {
                        x.permission = state.state
                    })
                })
                .catch(() => {
                    setState((x) => {
                        x.permission = 'unsupported'
                    })
                })
        } else {
            setState((x) => {
                x.permission = 'unsupported'
            })
        }
    }

    return { state, getLocation, refreshPermission, consentToGeoLocation }
}

export default function LocationServices() {
    const {
        state: { permission, location, locationAddDate },
        getLocation,
        refreshPermission,
    } = useLocationServices()
    refreshPermission()

    const visible = useVisibilityChange()
    useEffect(() => {
        if (!visible) {
            return
        }

        // ios doesnt support location api, therefore if we have
        // been granted access before, assume we still have it
        if (permission !== 'denied' && locationConsent.geoApiConsented()) {
            if (locationAddDate) {
                const elapsedTime = Date.now() - locationAddDate
                // 5 minutes
                if (elapsedTime < 1000 * 60 * 5) {
                    return
                }
            }
            getLocation()
        }
        if (permission === 'prompt' || permission === 'denied') {
            refreshPermission()
        }
    }, [visible, permission])
    return null
}
