import React, {
    useState,
    useEffect,
    HTMLAttributes,
    useRef,
    RefObject,
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import { hasTracingEnabled } from '@sentry/tracing'

const Container = styled.div`
    height: 100%;
`

export interface VirtualScrollProps extends HTMLAttributes<HTMLDivElement> {
    addData: (currentPage: number) => any
    refElement: RefObject<HTMLElement>
}

export function LazyScroll({
    addData,
    refElement,
    ...props
}: VirtualScrollProps) {
    const [content, setContent] = useState<any[]>([])

    const pageNoRef = useRef<number>(0)
    const hasEndedRef = useRef<boolean>(false)

    useEffect(() => {
        function scroll(x) {
            const ele = x.target as HTMLDivElement
            const percentage =
                ele.scrollTop / (ele.scrollHeight - ele.clientHeight)

            if (percentage > 0.7) {
                addContent()
            }
        }
        if (refElement.current) {
            refElement.current.addEventListener('scroll', scroll)
        }
        return () => {
            refElement.current &&
                refElement.current.removeEventListener('scroll', scroll)
        }
    }, [refElement.current])

    function addContent() {
        if (hasEndedRef.current) {
            return
        }
        pageNoRef.current = pageNoRef.current + 1
        const content = addData(pageNoRef.current)
        if (content) {
            setContent((x) => [...x, content])
        } else {
            hasEndedRef.current = true
        }
    }

    useEffect(() => {
        addContent()
    }, [])

    useEffect(() => {
        if (refElement.current) {
            window['ref'] = refElement.current
            if (
                !(
                    refElement.current.scrollHeight -
                    refElement.current.clientHeight
                )
            ) {
                addContent()
            }
        }
    }, [content, refElement.current])

    return (
        <Container {...props}>
            {content.map((C, index) => (
                <C key={index} />
            ))}
        </Container>
    )
}
