import React, { useState, useEffect, HTMLAttributes, RefObject } from 'react'
import styled, { css, keyframes } from 'styled-components'
import emojis from '../emojis.json'
import { SlideUpPanel } from './toolkit/SlideUpPanel'
import { atom } from 'recoil'
import { useImmerRecoilState } from '../state/immerRecoil'
import { clapperIconChangedEvent } from '../claps'
import { LazyResult } from 'postcss'
import { LazyScroll } from './toolkit/LazyScroll'

const filteredEmojis = emojis.filter((x) => x.length < 5)
const Container = styled.div`
    .emoji-button {
        font-size: 3rem;
        margin: 0.5rem;
    }
    .button-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
`

export interface EmojiState {
    clapperId?: number
    closed: boolean
}
export const emojiState = atom<EmojiState>({
    key: 'emojis',
    default: { closed: true },
})
export interface EmojiListProps extends HTMLAttributes<HTMLDivElement> {}

export function EmojiList({ ...props }: EmojiListProps) {
    const [state, setState] = useImmerRecoilState(emojiState)
    function selectEmoji(event) {
        const emoji = event.target.innerText
        clapperIconChangedEvent.raiseEvent({
            clapperId: state.clapperId,
            emoji: emoji,
        })
        setState((state) => {
            state.closed = true
        })
    }
    function reset() {
        clapperIconChangedEvent.raiseEvent({ clapperId: state.clapperId })
        setState((x) => {
            x.closed = true
        })
    }
    function close() {
        setState((x) => {
            x.closed = true
        })
    }
    // if (state.closed) {
    //     return null
    // }

    function getData(pageNo: number) {
        const pageSize = 100
        const start = pageNo * pageSize
        const end = start + pageSize - 1
        const emojis = filteredEmojis.slice(start, end)
        if (emojis.length === 0) {
            return undefined
        }
        return () => (
            <>
                {pageNo == 1 ? (
                    <button className="" onClick={reset}>
                        Reset
                    </button>
                ) : null}
                {emojis.map((x) => (
                    <button
                        className="emoji-button"
                        onClick={selectEmoji}
                        key={x}
                    >
                        {x}
                    </button>
                ))}
            </>
        )
    }

    const render = (ref: RefObject<HTMLElement>) => {
        return (
            <div className="button-list">
                <LazyScroll addData={getData} refElement={ref}></LazyScroll>
            </div>
        )
    }
    return (
        <Container {...props}>
            <SlideUpPanel
                closed={state.closed}
                close={close}
                render={render}
            ></SlideUpPanel>
        </Container>
    )
}
