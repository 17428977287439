export enum zoomLevel {
    min = 2000,
}
const yTileLength = 14
const xTileLength = 20
const multiplier = zoomLevel.min

export type Coordinates = [number, number]

export type LatLngBounds = [Coordinates, Coordinates]

// lat from -90 to +90;
function latOffset(multi: number = multiplier) {
    return 90 * multi
}

// -180 to 180;
function lngOffset(multi: number = multiplier) {
    return 180 * multi
}

function getRectForYx(yxCoords: Coordinates): [Coordinates, Coordinates] {
    function getTileEndYx([y, x]: Coordinates): Coordinates {
        return [y + yTileLength, x + xTileLength]
    }

    const start = yxCoords
    const end = getTileEndYx(yxCoords)
    return [start, end]
}

function getCenterOfRect(rect: LatLngBounds): Coordinates {
    const [startLat, startLng] = rect[0]
    const [endLat, endLng] = rect[1]
    const latDiff = endLat - startLat
    const lngDiff = endLng - startLng

    const lat = startLat + latDiff / 2
    const lng = startLng + lngDiff / 2
    return [lat, lng]
}

function getTileStartForYxCoords([y, x]: Coordinates): Coordinates {
    const xTile = Math.floor(x / xTileLength) * xTileLength
    const yTile = Math.floor(y / yTileLength) * yTileLength

    return [yTile, xTile]
}

function getYxCoords(
    [lat, lng]: Coordinates,
    multi: number = multiplier,
): Coordinates {
    lat = lat * multi
    lat = lat + latOffset(multi)

    lng = lng * multi
    lng = lng + lngOffset(multi)

    return [lat, lng]
}

function convertYxToLatLng(
    [y, x]: Coordinates,
    multi: number = multiplier,
): Coordinates {
    let lng = x - lngOffset(multi)
    lng = lng / multi

    let lat = y - latOffset(multi)
    lat = lat / multi

    return [lat, lng]
}

export function geoLocation([lat, lng]: Coordinates) {
    function getMarker(muliplier: zoomLevel = zoomLevel.min) {
        const yx = getYxCoords([lat, lng], muliplier)

        const tile = getTileStartForYxCoords(yx)
        const rect = getRectForYx(tile)
        const center = getCenterOfRect(rect)

        return convertYxToLatLng(center)
    }

    function getTile(multiplier: zoomLevel = zoomLevel.min): LatLngBounds {
        const yx = getYxCoords([lat, lng], multiplier)

        const tile = getTileStartForYxCoords(yx)
        const rect = getRectForYx(tile)

        return [convertYxToLatLng(rect[0]), convertYxToLatLng(rect[1])]
    }

    return { getMarker, getTile }
}
