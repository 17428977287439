import { eventStream } from '../events'
import { clappedEvent } from './clap.events'
import getLogger from '../utils/logger'
import { addClap } from './clap.api'
import { geoLocation } from '@claptastic/common'

const logger = getLogger('clap change handler')

eventStream.addEventListener((event) => {
    clappedEvent.applyDomainEvent(event, (x) => {
        const data = x.data
        let location = data.latlng || undefined
        if (location) {
            location = geoLocation(location).getMarker()
        }
        addClap({ userId: '', message: data.message, location })
    })
})
