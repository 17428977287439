import { atom } from 'recoil'
import { Coordinates } from '@claptastic/common'

export interface GlobalLocationsState {
    [key: string]: Coordinates
}
export const globalLocationsAtom = atom<GlobalLocationsState>({
    key: 'globalLocationsAtom',
    default: {},
})
