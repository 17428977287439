import { useEffect, useState } from 'react'

export function useVisibilityChange() {
    const [visible, setVisible] = useState(true)
    useEffect(() => {
        let hidden, visibilityChange
        const anyDoc = document as any
        if (typeof anyDoc.hidden !== 'undefined') {
            // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden'
            visibilityChange = 'visibilitychange'
        } else if (typeof anyDoc.msHidden !== 'undefined') {
            hidden = 'msHidden'
            visibilityChange = 'msvisibilitychange'
        } else if (typeof anyDoc.webkitHidden !== 'undefined') {
            hidden = 'webkitHidden'
            visibilityChange = 'webkitvisibilitychange'
        }
        async function handleVisibilityChange() {
            setVisible(!document[hidden])
        }

        document.addEventListener(
            visibilityChange,
            handleVisibilityChange,
            false,
        )
        return () =>
            document.removeEventListener(
                visibilityChange,
                handleVisibilityChange,
            )
    }, [])
    return visible
}
