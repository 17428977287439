'use strict'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { render } from 'react-dom'
import { CaptureConsole } from '@sentry/integrations'
import './claps'
import './external/external.db'
import './styles.css'
const logger = getLogger('main')
if (!!Config.sentry) {
    logger.log('Initialising Sentry')
    Sentry.init({
        dsn: 'https://698a6ff842d340e6b7b78f0eb4873ce5@o551318.ingest.sentry.io/5674666',
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({ levels: ['error', 'warn'] }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

import getLogger, { log } from './utils/logger'
import App from './components/App'

import * as mic from './utils/mic.utils'
import './db.legacy'
import ExternalDbSync from './external/external.db'
import Config from './config'

const env = Config.env
logger.log('Current environment', env)
if (env !== 'development') {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('service-worker.js')
                .then((registration) => {
                    logger.log('SW registered: ', registration)
                })
                .catch((registrationError) => {
                    logger.error('SW registration failed: ', registrationError)
                })
        })
    }
}

render(
    <RecoilRoot>
        <ExternalDbSync></ExternalDbSync>
        <App />
    </RecoilRoot>,
    document.getElementById('root'),
)
log('Initialising')
