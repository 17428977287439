import React, { useState, useEffect, HTMLAttributes } from 'react'
import styled, { css, keyframes } from 'styled-components'
import classNames from 'classnames'
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    .dot {
        border-radius: 999px;
        width: 0.9rem;
        height: 0.9rem;
        background-color: #e4dfdf;
        margin-right: 1rem;
    }
    .dot:last-child {
        margin-right: unset;
    }
    .selected {
        background-color: #5353ff;
    }
`

export interface SwiperDotsProps extends HTMLAttributes<HTMLDivElement> {
    numberOfDots: number
    current: number
}

export function SwiperDots({
    numberOfDots,
    current,
    ...props
}: SwiperDotsProps) {
    if (numberOfDots < 2) {
        return null
    }
    return (
        <Container {...props}>
            {[...Array(numberOfDots)].map((x, index) => (
                <div
                    key={index}
                    className={classNames('dot', {
                        selected: index === current,
                    })}
                ></div>
            ))}
        </Container>
    )
}
