import React, {
    useState,
    useEffect,
    HTMLAttributes,
    forwardRef,
    createRef,
    RefObject,
} from 'react'
import styled, { css, keyframes } from 'styled-components'

const openAnimation = keyframes`
from {margin-top: 100vh;}
to {margin-top:0; }
`

const closeAnimation = keyframes`
from {margin-top: 0;}
to {margin-top:100vh; }
`

const Container = styled.div`
    position: fixed;
    right: 5px;
    left: 5px;
    top: 5px;
    height: 100vh;
    border-radius: 5px;
    background-color: #000000eb;
    color: white;
    z-index: 200;

    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem 0.5rem;

    animation-name: ${(props: any) =>
        props.closed ? closeAnimation : openAnimation};
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier();
`

const CloseButton = styled.button`
    border: 1px solid white;
    border-radius: 0.2rem;
    padding: 0.5rem;
`

const Content = styled.div`
    overflow: auto;
    padding: 0 0.5rem;
    height: 100%;
    flex-grow: 1;
`

export interface SlidUpPanelProps extends HTMLAttributes<HTMLElement> {
    close?: () => void
    closed?: boolean
    render: (ref: React.RefObject<HTMLDivElement>) => any
}

export function SlideUpPanel({
    children,
    close,
    render,
    ...props
}: SlidUpPanelProps) {
    const ref = createRef<HTMLDivElement>()
    const [removeFromDom, setRemovedFromDom] = useState(true)
    function onAnimationEnd(x) {
        const name = x.animationName
        if (name === closeAnimation.getName()) {
            setRemovedFromDom(true)
        }
    }

    function onAnimationStart(x) {
        const name = x.animationName
        if (name === openAnimation.getName()) {
            setRemovedFromDom(false)
        }
    }

    // useEffect(() => {
    //     if (timer) return
    //     if (!props.closed) {
    //         setRemovedFromDom(false)
    //     }
    // }, [props.closed, removeFromDom])
    if (removeFromDom && props.closed) {
        return null
    }
    return (
        <Container
            {...props}
            onAnimationEnd={onAnimationEnd}
            onAnimationStart={onAnimationStart}
        >
            {close && <CloseButton onClick={close}>Close</CloseButton>}
            <Content ref={ref}>{render(ref)}</Content>
        </Container>
    )
}
