import getLogger from './logger'

const logger = getLogger('geo location')
export async function getCurrentLocation(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
        if (
            !navigator ||
            !navigator.geolocation ||
            !navigator.geolocation.getCurrentPosition
        ) {
            reject()
        }
        logger.log('geolocation.getCurrentPosition')

        navigator.geolocation.getCurrentPosition(resolve, reject)
    })
}
